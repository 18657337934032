a:hover.BodyLink01 {
    text-decoration: none;
    color: #333377;
    background-color: #dddddd;
}

a.BodyLink01 {
    text-decoration: none;
}

a:hover.BodyLink02 {
    text-decoration: none;
    color: #333377;
    background-color: #ffffff;
}

a.BodyLink02 {
    text-decoration: none;
}

.panelNormal {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -khtml-border-radius: 10px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    border: 2px solid #cccccc;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 17px;
}

.panelTitleNormal {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    text-align: left;
    border: 1px solid #cccccc;
    background-color: #EEEEEE;
    width: 100%;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 0px;
    text-indent: 5px;
    font-weight: bold;
}


.Button04 {
    text-decoration: none;
    font-family: tahoma,arial,helvetica;
    height: 25px;
    line-height: 25px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    min-width: 80px;
    text-align: center;
    font-size: 14px;
    font-weight: lighter;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 0.218s;
    -moz-transition: all 0.218s;
    -ms-transition: all 0.218s;
    -o-transition: all 0.218s;
    transition: all 0.218s;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: Navy;
    cursor: default;
    border: 1px solid #3079ED;
    cursor: pointer;
    text-shadow: 0 1px rgba(0, 0, 0, 0.1);
    background-color: #dde4ff;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#eeeeee),to(#dde4ff));
    background-image: -webkit-linear-gradient(top,#ffffff,#dde4ff);
    background-image: -moz-linear-gradient(top,#ffffff,#dde4ff);
    background-image: -ms-linear-gradient(top,#ffffff,#dde4ff);
    background-image: -o-linear-gradient(top,#ffffff,#dde4ff);
    background-image: linear-gradient(top,#ffffff,#dde4ff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dde4ff');
}

.Button04:hover {
    text-decoration: none;
    -webkit-transition: all 0.0s;
    -moz-transition: all 0.0s;
    -ms-transition: all 0.0s;
    -o-transition: all 0.0s;
    transition: all 0.0s;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);
    border: 1px solid #2F5BB7;
    color: #000000;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    background-color: #dde4ff;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#eeeeee),to(#dde4ff));
    background-image: -webkit-linear-gradient(top,#eeeeee,#dde4ff);
    background-image: -moz-linear-gradient(top,#eeeeee,#dde4ff);
    background-image: -ms-linear-gradient(top,#eeeeee,#dde4ff);
    background-image: -o-linear-gradient(top,#eeeeee,#dde4ff);
    background-image: linear-gradient(top,#eeeeee,#dde4ff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#dde4ff');
    cursor: hand;
}


.modalPopup {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -khtml-border-radius: 10px;
    border-radius: 10px;
    background-color: #f7f7f7;
    border-width: 3px;
    border-style: solid;
    border-color: Gray;
    padding: 3px;
    width: 250px;
    z-index: 100001;
    text-align: left;
}

.modalBackground {
    background-color: #bebebe;
    filter: alpha(opacity=70);
    opacity: 0.7;
}
