
/********** compatibility for ancient HTML in old pages *******/
/* HTML5 ignores cellpadding/cellspacing now, apparently the order of these does have an affect*/

td {
    padding: 0px;
}

table[cellpadding="0"] td {
    padding: 0px;
}

table[cellpadding="10"] td {
    padding: 10px;
}

table[cellpadding="3"] td {
    padding: 3px;
}

table[cellpadding="2"] td {
    padding: 2px;
}

table[cellpadding="1"] td {
    padding: 1px;
}



table {
    border-spacing: 0px;
    border-collapse: separate;
}

/*img { display: block;} */


img[src="/images/dot_clear.gif"] {
    display: block;
}


/*overrides less.css - checkbox on I-9 form*/
input[type="checkbox"] {
    margin: 3px 3px 3px 4px;
}

/*overrides less.css - labels for checkbox on I-9 form*/
label {
    display: inline-block;
    max-width: 100%;
    font-weight: normal;
    margin-bottom:0px;
}

/*overrides bootstrap, perhaps this can be scoped to the .modalPopup class*/
h3 {
    font-size:16px;
    font-weight:bold;
}

/*bootstrap 3x wells, no longer in 4*/
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
    padding: 24px;
    border-radius: 6px;
}

.well-sm {
    padding: 9px;
    border-radius: 3px;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}