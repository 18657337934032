/*note, at some point we should minimie https://www.queness.com/post/588/15-ways-to-optimize-css-and-reduce-css-file-size*/
body {
    width: 100%;
    margin: 0 auto;
}

/* top branding - to resize header images*/
.topRight img {
    max-height: 50px;
    width: auto !important;
}

.topLeft img {
    max-height: 50px;
    width:auto !important;
}

.topMiddle {
    max-height: 50px !important;
}

.topMiddle {
    height: 50px !important;
}

.topMiddle .spacer {
    height: 50px !important;
}

.topRight {
    height: 50px;
}

.topLeft {
    height: 50px;
    width:0;
}
/* end top branding - to resize header images*/

/* top navbar menu */
.navbar {
    margin-bottom: 0px;
    border: 0px solid transparent;
}


.navbar-toggle-menu {
    line-height: 1;
    margin-top:7px;
    margin-bottom:7px;

}

.navbar-toggle-menu.navbar-toggle-left {
    float: left;
    margin-left: 15px
}

.navbar-toggle-label {
    display: inline-block;
    vertical-align: top;
    color: #888;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase
}

.navbar-toggle-label + .navbar-toggle-icon {
    margin-left: 4px
}

.navbar-inverse-menu .navbar-toggle-label {
    color: #fff
}

.navbar-toggle-icon {
    display: inline-block
}

.navbar-toggle-icon > .icon-bar + .icon-bar {
    margin-top: 0
}

.navbar-toggle-icon {
    position: relative;
    width: 22px;
    height: 14px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer
}

.navbar-toggle-icon span {
    display: block;
    position: absolute;
    opacity: 1;
    left: 0;
    transition: .25s ease-in-out
}

.navbar-toggle.collapsed .icon-bar:nth-child(1) {
    top: 0
}

.navbar-toggle.collapsed .icon-bar:nth-child(2) {
    top: 6px
}

.navbar-toggle-menu.collapsed .icon-bar:nth-child(3) {
    top: 12px
}

.navbar-toggle-menu:not(.collapsed) .icon-bar:nth-child(1) {
    top: 6px;
    transform: rotate(45deg)
}

.navbar-toggle-menu:not(.collapsed) .icon-bar:nth-child(2) {
    opacity: 0
}

.navbar-toggle-menu:not(.collapsed) .icon-bar:nth-child(3) {
    top: 6px;
    transform: rotate(-45deg)
}

.navbar li a .menu-icon {
    font-size: 20px;
    line-height: 20px;
}

.fragcollapsebtn a[aria-expanded=true] .fa-chevron-right {
    display: none;
}

.fragcollapsebtn a[aria-expanded=false] .fa-chevron-down {
    display: none;
}

.fragcollapsebtn a[aria-expanded=true] .fa-angle-double-right {
    display: none;
}

.fragcollapsebtn a[aria-expanded=false] .fa-angle-double-left {
    display: none;
}

.fragcollapsebtn a[aria-expanded=false] .fa-chevron-left {
    display: none;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

#header-secondary li em {
  padding-top:10px;
  padding-bottom : 10px;
  display:block;
}
    /* top navbar menu */
    /****** side menu ****************** */
    #wrapper {
    padding-left: 0;
}

#wrapper.toggled {
    padding-left: 200px;
}

#sidebar-wrapper {
    /*top:150px; */
    z-index: 1000;
    position: absolute;
    left: 200px;
    width: 0;
    height: 100%;
    margin-left: -200px;
    overflow-y: auto;
    /*background: #000;*/
    /*font-family:Arial;*/
    /*font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 13px;
    font-weight:normal;
}


#wrapper.toggled #sidebar-wrapper {
    width: 200px;
}

#page-content-wrapper {
    width: 100%;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 0px;
    padding-left: 0px;
}

#wrapper.toggled #page-content-wrapper {
    position: relative;
    width: 100%;
}



.sidebar-nav {
    position: absolute;
    top: 0;
    width: 200px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 10px;
    line-height: 40px;
}

.sidebar-nav li a .arrow {
    position: absolute;
    right: 20px;
    font-size: 18px;
    text-align: center;
    margin-top: 12px;
}

.sidebar-nav li a .menu-icon, .menu-icon {
    font-size: 18px;
    margin-right: 6px;
}


.panel-collapse li a .menu-icon {
    font-size: 12px;
    position: absolute;
    left: -10px;
    display: none; /*for now*/
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}


@media(min-width:11768px) { 
    #wrapper {
        padding-left: 200px;
    }

        #wrapper.toggled {
            padding-left: 0;
        }

    #sidebar-wrapper {
        width: 200px;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 0;
    }

    #page-content-wrapper {
        padding: 20px;
        position: relative;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0px;
        padding-left: 0px;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-right: 0;
    }
}

@media (min-width: 768px) {

    .navbar-toggle-always {
        display: block !important;
    }
}

@media(max-wdith: 600px){
    #wrapper {
        padding-left: 0px;
    }
    #sidebar-wrapper {
        width: 0px;
    }

}




/****** side menu ****************** */

#innerpagecontent {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    min-height: 760px;
    text-align:left;
}

/*maybe for the old  master page*/
#sidebar-shortcuts {
    background-color: #333333;
}